body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ion-page{width:100%}
code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;}
.no-underline{text-decoration:none;}
.ion-page {display: flex;flex-direction: column;height: 100dvh;}
ion-content {flex: 1;overflow: auto;}